
import { defineComponent } from 'vue';
import { Form } from 'vee-validate';
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import SearchBar from '@/components/search-bars/SearchBar.vue';
import Swal from 'sweetalert2/dist/sweetalert2.min.js';
// import * as Yup from "yup";
import ApiService from '@/core/services/ApiService';
import { VueCookieNext } from 'vue-cookie-next';
import { apiEndpoint } from '@/mixin/apiEndpoint';
import axios from 'axios';
import { ElNotification } from 'element-plus';
import moment from 'moment';

export default defineComponent({
    mixins: [apiEndpoint],
    name: 'trainer-list',
    components: {
        Form,
        Datatable,
        SearchBar,
        Swal,
    },
    data() {
        return {
            user: '',
            showcalendarDetails: false,
            trainingCalendar: [] as any,
            is_seip_user: false,
            is_entity_user: false,
            is_institute_user: false,
            moment: '' as any,
            selectAll: false,
            api_url: '',
            load: false,
            loading: false,
            tranche: [] as any,
            tranches: [] as any,
            association: [] as any,
            btnCheck: false,
            instituteCheck: false,
            instituteList: [] as any,
            batch: {
                entity_id: '',
                tranche_id: '',
                institute_id: '',
                course_id: '',
            },
            tableHeader: [
                {
                    name: 'Actions',
                    key: 'actions',
                    sortable: false,
                    width: '200px',
                },
                {
                    name: 'Unique Course',
                    key: 'activity_report',
                    sortable: true,
                },
                {
                    name: 'Code',
                    key: 'code',
                    sortable: true,
                },
                {
                    name: 'Name of industry Association / Govt. Org',
                    key: 'association',
                    sortable: true,
                    width: '300px',
                },
                {
                    name: 'Course Found',
                    key: 'course',
                    sortable: true,
                },
            ],
            actionDelete:false,
            actionApprove: false,
            actionEdit: false,
            tranche_id: '' as any,
            checkedItems: [] as any,
            showReport: false,
            entity_id: '' as any,
            course_type: '' as any,
            type: '' as any,
            start_date: '' as any,
            end_date: '' as any,
            showActivityReport: false,
            componentKey: 0,
            actionActive: false,
            activityReport: [] as any,
            TotalTrainee: 0 as any,
            Totalenrollment: 0 as any,
            Totalfemale: 0 as any,
            Totalcertification: 0 as any,
            TotalcertificationFemale: 0 as any,
            Totalcertification_percentage: 0 as any,
            total_job_placement: 0 as any,
            total_female_job_placement: 0 as any,
            Totaljob_placement_percentage: 0 as any,
        };
    },
    async created() {
        if (VueCookieNext.getCookie("_seip_entity_type") == 1004 || VueCookieNext.getCookie("_seip_entity_type") == 1000) {
            this.is_seip_user = true;
        }
        if (VueCookieNext.getCookie("_seip_entity_type") != 1004 && VueCookieNext.getCookie("_seip_entity_type") != 1011) {
            this.is_entity_user = true;
            this.is_institute_user = true;
        }
        if (VueCookieNext.getCookie("_seip_entity_type") == 1011) {
            this.is_institute_user = true;
        }
        this.api_url = this.VUE_APP_API_URL;
        this.moment = moment;
        await this.actioncheck();
        await this.getTranches();
        await this.getAssociation();
        this.emitter.on('certificate-updated', async () => {
            this.courseActivityReport();
        });
    },
    methods: {
        async actioncheck() {
            let menu = JSON.parse(localStorage.getItem('menu') || '{}');
            if (menu) {
                for (let i = 0; i < menu.length; i++) {
                    //console.log(menu[i].action);
                    if (menu[i].heading == 'Reporting') {
                        //console.log(menu[i].action);
                        let actions = menu[i].action;
                        console.log(actions);
                        for (let j = 0; j < actions.length; j++) {
                            if (actions[j].action_name === 'Approve') {
                                this.actionApprove = true;
                            }
                            if (actions[j].action_name === 'Edit') {
                                this.actionEdit = true;
                            }
                            if (actions[j].action_name === 'Delete') {
                                this.actionDelete = true;
                            }
                        }
                    }
                }
            }
        },

        async editCourse(data) {
            this.emitter.emit('cours-update-drawer', data);
        },

        handleCheckboxChange(index) {
            const checkedItems = this.activityReport.filter((item) => item.checked);
            this.selectAll = checkedItems.length === this.activityReport.length;
        },

        handleSelectAll() {
            this.activityReport.forEach((item) => {
                item.checked = this.selectAll;
            });
        },

        
        async handleDelete() {
            Swal.fire({
                title: 'Are you sure you want to Delete ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
            }).then((result) => {
                if (result.isConfirmed) {
                    let formData = new FormData();
                    const calendar_data = this.activityReport
                        .filter((item) => item.checked === true)
                        .map((item) => item.id);

                    formData.set('checked_update_data', JSON.stringify(calendar_data));
                     ApiService.post(
                        'asset_register/delete',
                        formData)
                        .then((response) => {
                            //this.trainerInfo();
                            Swal.fire('Deleted!', response.data.message, 'success').then(
                                (result) => {
                                    if (result.isConfirmed) {
                                        this.emitter.emit('certificate-updated', true);
                                    }
                                }
                            );
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });
                }
            });

        },

        async handleUpdateClick() {
            Swal.fire({
                title: 'Are you sure you want to Approve ?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, Approve!',
            }).then((result) => {
                if (result.isConfirmed) {
                    if (VueCookieNext.getCookie("_seip_employee_id")) {
                        this.user = VueCookieNext.getCookie("_seip_employee_id");
                    }
                    let formData = new FormData();
                    const calendar_data = this.activityReport
                        .filter((item) => item.checked === true)
                        .map((item) => item.id);
                    formData.set('checked_update_data', JSON.stringify(calendar_data));
                    formData.set('approved_by', this.user);
                     ApiService.post(
                        'asset_register/status_change',
                        formData
                    )
                        .then((response) => {
                            //this.trainerInfo();
                            Swal.fire('Approved!', response.data.message, 'success').then(
                                (result) => {
                                    if (result.isConfirmed) {
                                        this.emitter.emit('certificate-updated', true);
                                    }
                                }
                            );
                        })
                        .catch(({ response }) => {
                            console.log(response);
                        });
                }
            });
        },

        async printPdf() {
            let entity_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            else {
                entity_id = this.batch.entity_id;
            }
            let institute_info_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }
            else {
                institute_info_id = this.batch.institute_id;
            }
            let formData = new FormData();
            formData.set('entity', entity_id);
            formData.set('institute', institute_info_id);
            formData.set('tranche', this.tranche_id);
            formData.set('type', this.type);
            let data = `${this.VUE_APP_API_URL}/api/asset_register/asset_register_print`;
            axios
                .post(data, formData)
                .then(response => {
                    //window.open(data, '_blank');
                    document.write(response.data);
                    window.print();
                    location.reload();
                    //open the new window and write your HTML to it         
                })
        },
        async downloadPdf() {
            ElNotification({
                dangerouslyUseHTMLString: true,
                message:
                    '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
            });
            let entity_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            else {
                entity_id = this.batch.entity_id;
            }
            let institute_info_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }
            else {
                institute_info_id = this.batch.institute_id;
            }
            let formData = new FormData();
            formData.set('entity', entity_id);
            formData.set('institute', institute_info_id);
            formData.set('tranche', this.tranche_id);
            formData.set('type', this.type);

            await ApiService.post('asset_register/asset_register_pdf', formData)
                .then((response) => {
                    Swal.fire({
                        title: 'Download Successfull!',
                        icon: 'success',
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'View/Download PDF',
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // redirect to new tab

                            let path = `${this.VUE_APP_API_URL}/${response.data}`;

                            window.open(path, '_blank');
                        }
                    });
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async exportTrainee() {
            ElNotification({
                dangerouslyUseHTMLString: true,
                message:
                    '<i class="fa fa-download" aria-hidden="true"></i> <b>Downloading...</b>',
            });
            let entity_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            else {
                entity_id = this.batch.entity_id;
            }
            let institute_info_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }
            else {
                institute_info_id = this.batch.institute_id;
            }
            let formData = new FormData();
            formData.set('entity', entity_id);
            formData.set('institute', institute_info_id);
            formData.set('tranche', this.tranche_id);
            formData.set('type', this.type);
            axios
                .post(
                    `${this.VUE_APP_API_URL}/api/asset_register/asset_register_excel`,
                    formData,
                    {
                        responseType: 'blob',
                    }
                )
                .then((response) => {
                    const url = URL.createObjectURL(
                        new Blob([response.data], {
                            type: 'application/vnd.ms-excel',
                        })
                    );
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'asset_register.xlsx');
                    document.body.appendChild(link);
                    link.click();
                })
                .catch((response) => {
                    console.log(response);
                });
        },
        async getTranches() {
            await ApiService.get("configurations/tranche/list")
                .then((response) => {
                    this.tranches = response.data.data;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async getAssociation() {
            let entity_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            else {
                entity_id = this.batch.entity_id;
            }
            await ApiService.get('entity/list?entity_id=' + entity_id)
                .then((response) => {
                    this.association = response.data.data;
                    console.log(response);
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async courseActivityReport() {
            this.load = true;
            let entity_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') != 1004) {
                entity_id = VueCookieNext.getCookie('_entity_id');
            }
            else {
                entity_id = this.batch.entity_id;
            }
            let institute_info_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }
            else {
                institute_info_id = this.batch.institute_id;
            }
            await ApiService.get(
                'asset_register/list?entity=' +
                entity_id +
                '&institute=' +
                institute_info_id +
                '&type=' +
                this.type +
                '&tranche=' +
                this.tranche_id
                +
                '&status=' +
                2
            )
                .then((response) => {
                    this.activityReport = response.data.data;
                    console.log(this.activityReport);
                    this.componentKey += 1;
                    this.showReport = true;
                    this.load = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },
        async btnCheckStatus() {
            this.load = true;
            if (this.type) {
                if (this.type !== 'SEIP') {
                    this.btnCheck = true;
                }
                else {
                    this.btnCheck = false;
                    this.instituteCheck = false;
                }
                if (this.type == 'Training Institute') {
                    this.instituteCheck = true;
                }
                else {
                    this.instituteCheck = false;
                }

                this.load = false;
            } else {
                Swal.fire({
                    title: 'Organization Type Need to be Select!',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, delete!',
                });
            }
            // else popup
        },
        view(data) {
            // console.log(data);
            this.emitter.emit('course_unique_details', data);
        },

        async getTranche() {
            await ApiService.get('configurations/tranche/list')
                .then((response) => {
                    console.log(response.data.data);
                    this.tranche = response.data.data;
                    this.tranche.unshift(this.selectAll);
                })
                .catch(({ response }) => {
                    console.log(response);
                });
        },

        async getTrainingInstitute() {
            this.loading = true;
            let institute_info_id = '';
            if (VueCookieNext.getCookie('_seip_entity_type') == 1011) {
                institute_info_id = VueCookieNext.getCookie('_institute_info_id');
            }
            else {
                institute_info_id = this.batch.institute_id;
            }
            await ApiService.get(
                this.VUE_APP_INSTITUTE_LIST_API +
                '?entity_id=' +
                this.batch.entity_id +
                '&institute_info_id=' +
                institute_info_id
            )
                .then((response) => {
                    this.instituteList = response.data.data;
                    this.loading = false;
                })
                .catch(({ response }) => {
                    console.log(response);
                    this.loading = false;
                });
        },

        // async actioncheck() {
        //   let menu = JSON.parse(localStorage.getItem("menu") || "{}");
        //   if (menu) {
        //     for (let i = 0; i < menu.length; i++) {
        //       //console.log(menu[i].action);
        //       if (menu[i].heading == "Trainer") {
        //         //console.log(menu[i].action);
        //         let actions = menu[i].action;
        //         //console.log(actions);
        //         for (let j = 0; j < actions.length; j++) {
        //           if (actions[j].action_name === "Edit") {
        //             // console.log(actions[j].action_name);
        //             this.actionEdit = true;
        //           }
        //           if (actions[j].action_name === "Delete") {
        //             // console.log(actions[j].action_name);
        //             this.actionDelete = true;
        //           }
        //           if (actions[j].action_name === "Approve") {
        //             // console.log(actions[j].action_name);
        //             this.actionActive = true;
        //           }
        //         }
        //       }
        //     }
        //   }
        // },
    },
    setup() {
        const AssociationSchema = [];
        return {
            AssociationSchema,
        };
    },
});
